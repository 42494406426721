import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Markdown from '../components/markdown'
import Rows from '../components/rows'
import SEO from '../components/seo'

const ProjectTemplate = (props) => {

  const {
    projectDetails,
    projectRows,
    title,
    thumbnail
  } = props.data.contentfulProject;

  const homepage = props.data.allContentfulHomepage.edges[0].node;
  const shareImage = thumbnail ? thumbnail.fluid.src : null

  return (
    <Layout
      {...homepage}
      projectDetails={projectDetails}
      // bgColor={"#e6ebec"}
      // fgColor={"#000000"}
      hasFooter
    >
      <SEO title={`Make-Ready | ${title}`} shareImage={shareImage} />
      <Rows className="innerx8 max80" sections={projectRows} />
      <section className="innerx4 container">
        <Markdown className="innerx4 max54 center" html={homepage.projectFooterCopy.childMarkdownRemark.html} />
      </section>
    </Layout>
  )
}

ProjectTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default ProjectTemplate

export const pageQuery = graphql`
  query projectQuery($slug: String!) {
    contentfulProject(slug: {eq: $slug}) {
      title
      slug
      smallTitle {
        childMarkdownRemark {
          html
        }
      }
      masthead {
        childMarkdownRemark {
          html
        }
      }
      projectDetails {
        childMarkdownRemark {
          html
        }
      }
      projectRows {
        heroCopy {
          childMarkdownRemark {
            html
          }
        }
        heroImage {
          fluid(maxWidth: 1200, quality: 90) {
            sizes
            srcSet
            aspectRatio
          }
        }
        heroSize
        caption {
          childMarkdownRemark {
            html
          }
        }
        leftImage {
          fluid(maxWidth: 800, quality: 90) {
            sizes
            srcSet
            aspectRatio
          }
        }
        rightImage {
          fluid(maxWidth: 800, quality: 90) {
            sizes
            srcSet
            aspectRatio
          }
        }
      }
      cover {
        fluid(maxWidth: 1200, quality: 90) {
          sizes
          srcSet
          aspectRatio
        }
      }
      coverSize
      coverCopy {
        childMarkdownRemark {
          html
        }
      }
      thumbnail {
        fluid(maxWidth: 800, quality: 90) {
          src
          sizes
          srcSet
          aspectRatio
        }
      }
      thumbnailText {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulHomepage (
      limit: 1
    ) {
      edges {
        node {
          contactDetails {
            childMarkdownRemark {
              html
            }
          }
          mapLink
          email
          telephone
          projectFooterCopy {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
