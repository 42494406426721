import React from 'react'
import Markdown from '../components/markdown'
import Picture from '../components/picture'

const Rows = ({className, sections}) => {

  return (
    <div className={className}>
      {sections.map((row, i) => {
        return row.heroImage ? (
          <section className="container project-row" key={`row-${i}`}>
            {!row.heroCopy || <Markdown className="max54 innerx4 center" html={row.heroCopy.childMarkdownRemark.html} />}
            <figure>
              <Picture className={`${row.heroSize ? 'pro-height' : ''}`} {...row.heroImage.fluid} />
              {!row.caption || (
                <figcaption className="innerx4">
                  <span>
                    <Markdown html={row.caption.childMarkdownRemark.html} />
                  </span>
                </figcaption>
              )}
            </figure>
          </section>
        ) : row.leftImage && row.rightImage ? (
          <section className="container project-row" key={`row-${i}`}>
            {!row.heroCopy || <Markdown className="span-8 innerx4 center" html={row.heroCopy.childMarkdownRemark.html} />}
            <div className="cols twoup">
              <div className="span-6 twoup__left">
                <Picture {...row.leftImage.fluid} />
              </div>
              <div className="span-6 twoup__right">
                <Picture {...row.rightImage.fluid} />
              </div>
            </div>
          </section>
        ) : row.leftImage ? (
          <section className="row-bottom-right container project-row" key={`row-${i}`}>
            <Picture className="span-6" {...row.leftImage.fluid} />
            <div className="span-6 nudge">
              {!row.caption || <Markdown className="markdown guttersx4 span-8" html={row.caption.childMarkdownRemark.html} />}
            </div>
          </section>
        ) : row.rightImage ? (
          <section className="row-bottom-right--reverse container project-row" key={`row-${i}`}>
            <div className="span-6">
              {!row.caption || <Markdown className="markdown side-caption span-8" html={row.caption.childMarkdownRemark.html} />}
            </div>
            <Picture className="span-6 nudge" {...row.rightImage.fluid} />
          </section>
        ) : null;
      })}
    </div>
  )
}

export default Rows
